import { useState } from "react";

import { useNetwork } from "app/providers/network";
import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { Icon } from "shared/ui/Icon";
import { Popover } from "shared/ui/Popover";
import { Spinner } from "shared/ui/Spinner";

const sectionTitleStyles = "text-xs text-corduroy-500";

export const ChainPopover = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const latestBlock = useBlocksStore((state) => state.blocksHistory[0]);
  const { network } = useNetwork();

  return (
    <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
      <Popover.Trigger className="flex items-center gap-1 overflow-hidden rounded-md bg-primary-50 p-1 transition-colors hover:bg-primary-100 lg:p-2">
        <div className="flex size-8 items-center justify-center rounded-full border-2 border-primary-100 bg-white lg:size-10">
          <Icon className="size-5 text-primary-900 lg:size-7" name="layerGroup" />
        </div>

        <div className="flex flex-1 flex-col items-start justify-start gap-0">
          <div className="flex items-center text-xs font-medium text-corduroy-900 lg:text-base">
            #{latestBlock ? latestBlock?.latestBlock : <Spinner className="mx-1 size-3" />}
          </div>
          <div className="line-clamp-1 w-full max-w-20 text-xs text-corduroy-500 xs:max-w-max">
            {network.rest}
          </div>
        </div>

        <div>
          <Icon name="arrowDownSm" />
        </div>
      </Popover.Trigger>
      <Popover.Content align="start" className="mt-1.5 w-full min-w-fit border border-corduroy-100">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-0">
            <h4 className={sectionTitleStyles}>Rest endpoint</h4>
            <div>{network.rest}</div>
          </div>
          <div>
            <h4 className={sectionTitleStyles}>Chain Id</h4>
            <div>{network.chainId}</div>
          </div>
          <div>
            <h4 className={sectionTitleStyles}>Chain Name</h4>
            <div>{network.chainName}</div>
          </div>

          <div>
            <h4 className={sectionTitleStyles}>Height</h4>
            <div>
              {latestBlock ? latestBlock?.latestBlock : <Spinner className="mx-1 size-3" />}
            </div>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
};
