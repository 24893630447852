import type { ChainInfo } from "@keplr-wallet/types";

import { type PropsWithChildren, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";
import { useAccount } from "graz";
import { twMerge } from "tailwind-merge";

import { useAppStore } from "app/store";
import { WalletModal } from "features/WalletModal/WalletModal";
import { WalletProviderModal } from "features/WalletProviderModal";
import { getChainById } from "shared/helpers/getChainById";
import { getShortenedAddress } from "shared/helpers/getShortenedAddress";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { Button } from "shared/ui/Button";
import { Icon, type IconName } from "shared/ui/Icon";

type MenuItem = {
  icon: IconName;
  label: string;
  link: string;
};

const getMenuItemsNesa = (chain: ChainInfo): MenuItem[] => [
  { icon: "chartPie", label: "Dashboard", link: `/${chain.chainId}/dashboard` },
  { icon: "arrowTransfer", label: "Transactions", link: `/${chain.chainId}/transactions` },
  { icon: "layers", label: "Blocks", link: `/${chain.chainId}/blocks` },
  { icon: "governance", label: "Governance", link: `/${chain.chainId}/governance` },
  { icon: "clockFill", label: "Uptime", link: `/${chain.chainId}/uptime` },
  { icon: "cube", label: "Supply", link: `/${chain.chainId}/supply` },
];

const menuItemsEcosystem: MenuItem[] = [
  // { icon: "starFill", label: "Favorite", link: "/" },
  { icon: "starFill", label: "Favorite", link: "/favorite" },
  { icon: "apps", label: "All Blockchains", link: "/blockchains" },
];

export const Sidebar = () => {
  const { openedSidebar, setOpenedSidebar } = useAppStore();
  const lg = useMinWidthMediaQuery("lg");

  const { data: account, isConnected } = useAccount();
  const { chainId } = useParams<{ chainId: string }>();
  const chain = getChainById(chainId);

  const [isProviderOpen, setIsProviderOpen] = useState(false);
  const [isWalletOpen, setIsWalletOpen] = useState(false);

  const menuItems = getMenuItemsNesa(chain);

  return (
    <>
      <AnimatePresence>
        {(lg || openedSidebar) && (
          <>
            {!lg && openedSidebar && (
              <motion.div
                animate={{ opacity: 1 }}
                className="fixed inset-x-0 bottom-0 top-[var(--header-height)] z-10 bg-corduroy-900/40"
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                onClick={() => setOpenedSidebar(false)}
                transition={{ duration: 0.2 }}
              />
            )}
            <motion.aside
              animate={{ translateX: "0%" }}
              className="fixed bottom-0 left-0 top-[var(--header-height)] z-20 w-[var(--sidebar-width)] bg-white px-3 py-6"
              exit={{ translateX: "-100%" }}
              initial={{ translateX: "-100%" }}
              transition={{ duration: 0.2 }}
            >
              {!lg &&
                (isConnected ? (
                  <Button
                    className="mb-4 w-full"
                    onClick={() => setIsWalletOpen(true)}
                    variant="filled-light"
                  >
                    {account && getShortenedAddress(account.bech32Address)}
                  </Button>
                ) : (
                  <Button
                    className="mb-4 w-full"
                    onClick={() => {
                      setIsProviderOpen(true);
                    }}
                    variant="filled-light"
                  >
                    Connect wallet
                  </Button>
                ))}
              <SidebarTitle>{chain.chainName}</SidebarTitle>
              <MenuItems items={menuItems} />
              <div className="my-6 h-px bg-corduroy-200" />
              <SidebarTitle>Ecosystem</SidebarTitle>
              <MenuItems items={menuItemsEcosystem} />
            </motion.aside>
          </>
        )}
      </AnimatePresence>
      <WalletProviderModal isOpen={isProviderOpen} onOpenChange={setIsProviderOpen} />
      <WalletModal isOpen={isWalletOpen} onOpenChange={setIsWalletOpen} />
    </>
  );
};

function SidebarTitle({ children }: PropsWithChildren) {
  return <h4 className="mb-4 pl-3 text-base text-corduroy-500">{children}</h4>;
}

type MenuItemsProps = {
  items: MenuItem[];
};

function MenuItems({ items }: MenuItemsProps) {
  return (
    <div className=" grid grid-cols-1 gap-2.5">
      {items.map((item) => (
        <NavLink
          className={({ isActive }) =>
            twMerge(
              "flex items-center gap-3 rounded-lg bg-white px-3 py-2 transition-all duration-200 hover:bg-primary-100",
              isActive && "bg-primary-200",
            )
          }
          key={item.link}
          to={item.link}
        >
          {({ isActive }) => (
            <>
              <Icon
                className={twMerge("size-6 text-corduroy-500", isActive && "text-primary-1000")}
                name={item.icon}
              />
              <div className=" text-base font-medium text-corduroy-900">{item.label}</div>
            </>
          )}
        </NavLink>
      ))}
    </div>
  );
}
