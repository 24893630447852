import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DateTime } from "luxon";

import type { TransactionQueryResponse } from "shared/api/transactions/useLastTransaction";

import { TableBlocks } from "features/TableBlocks";
import { TableTransactions } from "features/TableTransactions";
import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { ProposalItem } from "pages/Governance/ui/ProposalItem";
import { useSupplyByDenomQuery } from "shared/api/bank/useSupplyByDenomQuery";
import { useTotalAccountsQuery } from "shared/api/explorer-accounts/useTotalAccountsQuery";
import { useProposalsQuery } from "shared/api/proposals";
import { useStakingPoolQuery } from "shared/api/staking/useStakingPoolQuery";
import { useLastTransactionQuery } from "shared/api/transactions/useLastTransaction";
import { useTotalTransactionByPeriodQuery } from "shared/api/transactions/useTotalTransactionByPeriodQuery";
import { useTotalTransactionQuery } from "shared/api/transactions/useTotalTransactionQuery";
import { fromUnesToNes } from "shared/helpers/fromUnesToNes";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Button } from "shared/ui/Button";
import { Card } from "shared/ui/Card";
import { EmptyText } from "shared/ui/EmptyText";
import { LineChart } from "shared/ui/LineChart";
import { Spinner } from "shared/ui/Spinner";

import { StatisticsCard } from "./ui/StatisticsCard";
import bondedTokensSrc from "./ui/assets/bonded.svg";
import blockHeightSrc from "./ui/assets/height.svg";
import supplySrc from "./ui/assets/supply.svg";
import totalAccSrc from "./ui/assets/totalaccounts.svg";
import totalTxsSrc from "./ui/assets/totaltxs.svg";
import validatorsSrc from "./ui/assets/validator.svg";

const now = DateTime.now();

export const Dashboard = () => {
  const { chainId } = useParams<{ chainId: string }>();
  const navigate = useNavigate();

  const network = getChainById(chainId || "");

  const blocks = useBlocksStore((state) => state.blocksHistory);
  const latestBlock = blocks[0];
  const isLatestLoading = !latestBlock;

  const { data: latestTransactions, isLoading: isLatestTransactionsLoading } =
    useLastTransactionQuery({ limit: 20, skip: 0 });

  const txs = useMemo(() => {
    const txs = blocks.flatMap(({ latestBlock, transactions }) =>
      transactions.map((tx) => ({ blockHeight: latestBlock, tx })),
    );
    if (txs.length >= 7) return txs;
    const set = new Set(txs.map(({ tx }) => tx));

    const result: {
      blockHeight: string;
      tx: TransactionQueryResponse | string;
    }[] = [...txs];

    latestTransactions?.list.forEach((tx) => {
      if (!set.has(tx.txhash)) {
        result.push({ blockHeight: tx.tx_response.height, tx });
      }
    });
    return result;
  }, [latestTransactions, blocks]);

  const { data: supply, isLoading: isSupplyLoading } = useSupplyByDenomQuery({ network });
  const { data: poolData, isLoading: isPoolLoading } = useStakingPoolQuery({ network });

  const { data: totalTransactions, isLoading: isTotalTransactionsLoading } =
    useTotalTransactionQuery({ refetchInterval: 5 * 1000 });

  const { data: txChart, isPending: isLoadingTxChart } = useTotalTransactionByPeriodQuery({
    endDate: now.endOf("day").toISO(),
    startDate: now.minus({ days: 12 }).startOf("day").toISO(),
  });

  const { data: totalAccounts, isLoading: isTotalAccountsLoading } = useTotalAccountsQuery({
    network,
  });

  const { data: proposals, isLoading: isProposalsLoading } = useProposalsQuery({ network });

  const filtered = proposals?.proposals.filter(
    (el) => el.status === "PROPOSAL_STATUS_VOTING_PERIOD",
  );

  return (
    <AnimateRoute className="grid grid-cols-1 gap-5 p-6">
      <div className="grid grid-cols-1 gap-5 xl:grid-cols-[3fr_2fr]">
        <div className="grid grid-cols-1 rounded-lg bg-white shadow-md md:grid-cols-2 sm:[&>*:nth-child(odd)]:border-r sm:[&>*:nth-child(odd)]:border-r-corduroy-100">
          <StatisticsCard
            iconSrc={blockHeightSrc}
            isLoading={isLatestLoading}
            title="Block Height"
            value={getFormattedNumber(latestBlock?.latestBlock)}
          />
          <StatisticsCard
            iconSrc={validatorsSrc}
            isLoading={isLatestLoading}
            title="Validators"
            value={getFormattedNumber(latestBlock?.validators)}
          />
          <StatisticsCard
            iconSrc={supplySrc}
            isLoading={isSupplyLoading}
            title="Supply"
            value={
              supply?.amount?.amount
                ? getFormattedNumber(fromUnesToNes(supply.amount.amount).toNumber())
                : "-"
            }
          />
          <StatisticsCard
            iconSrc={bondedTokensSrc}
            isLoading={isPoolLoading}
            title="Bonded Tokens"
            value={
              poolData?.pool.bonded_tokens
                ? getFormattedNumber(fromUnesToNes(poolData?.pool.bonded_tokens).toNumber())
                : poolData?.pool.bonded_tokens
            }
          />
          <StatisticsCard
            iconSrc={totalTxsSrc}
            isLoading={isTotalTransactionsLoading}
            title="Total Transactions"
            value={totalTransactions ? getFormattedNumber(totalTransactions.total) : "-"}
          />
          <StatisticsCard
            iconSrc={totalAccSrc}
            isLoading={isTotalAccountsLoading}
            title="Total Accounts"
            value={totalAccounts ? getFormattedNumber(totalAccounts) : "-"}
          />
        </div>

        <Card className="p-5">
          <h4 className="font-medium">Transaction Count</h4>

          <div className="min-h-44 flex-1">
            {isLoadingTxChart ? (
              <div className=" flex h-full items-center justify-center">
                <Spinner className=" size-8" />
              </div>
            ) : txChart?.length ? (
              <LineChart data={txChart} />
            ) : (
              <EmptyText>Empty</EmptyText>
            )}
          </div>
        </Card>
      </div>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <Card>
          <Card.Title className="flex items-center justify-between py-1">
            Latest Transactions
            <Button
              color="secondary"
              onClick={() => navigate(`/${network.chainId}/transactions`)}
              variant="filled-light"
            >
              View all
            </Button>
          </Card.Title>

          {isLatestTransactionsLoading ? (
            <Card.Content>
              <Spinner className="size-7" />
            </Card.Content>
          ) : (
            <TableTransactions transactionsCount={7} txs={txs} withHeader={false} />
          )}
        </Card>

        <Card>
          <Card.Title className="flex items-center justify-between py-1">
            Latest Blocks
            <Button
              color="secondary"
              onClick={() => navigate(`/${network.chainId}/blocks`)}
              variant="filled-light"
            >
              View all
            </Button>
          </Card.Title>
          {isLatestLoading ? (
            <Card.Content>
              <Spinner className="size-7" />
            </Card.Content>
          ) : (
            <TableBlocks blocksCount={7} withHeader={false} />
          )}
        </Card>
      </div>

      <Card>
        <Card.Title className="flex items-center justify-between py-1">
          Active Proposals
          <Button
            color="secondary"
            onClick={() => navigate(`/${network.chainId}/governance`)}
            variant="filled-light"
          >
            View all
          </Button>
        </Card.Title>

        {(isProposalsLoading || isPoolLoading) && (
          <Card.Content>
            <Spinner className="size-7" />
          </Card.Content>
        )}
        {!isProposalsLoading && !isPoolLoading && (
          <div>
            {filtered?.length ? (
              filtered.map((proposal) => (
                <ProposalItem key={proposal.id} poolData={poolData} proposal={proposal} />
              ))
            ) : (
              <EmptyText className="py-7">No Active Proposals</EmptyText>
            )}
          </div>
        )}
      </Card>
    </AnimateRoute>
  );
};
