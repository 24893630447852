import { useCallback, useId, useState } from "react";

import { DateTime } from "luxon";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { theme } from "app/theme";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";

type Props = {
  data: {
    date: string;
    total: number;
  }[];
  isEmptyData?: boolean;
};

export const LineChart = ({ data, isEmptyData }: Props) => {
  const [yAxisWidth, setYAxisWidth] = useState(20);

  const xl2 = useMinWidthMediaQuery("2xl");

  const axisFontSize = xl2 ? 10 : 8;

  const gradId = useId();

  const tickFormatter = useCallback(
    (balance: number) => {
      const label = `${balance}`;
      const width = label.length * axisFontSize;
      setTimeout(() => setYAxisWidth((prev) => (prev > width ? prev : width)));

      return getFormattedNumber(label);
    },
    [axisFontSize],
  );

  return (
    <ResponsiveContainer>
      <AreaChart data={data} margin={{ bottom: 0, left: 0, right: 24, top: 24 }}>
        <CartesianGrid stroke="#C7F0ED" strokeWidth={1} />

        <YAxis
          allowDataOverflow={false}
          allowDecimals={false}
          axisLine={false}
          domain={isEmptyData ? [0, 4] : undefined}
          minTickGap={0}
          tick={{
            color: theme.colors.corduroy[700],
            fontFamily: "Inter",
            fontSize: axisFontSize,
            fontWeight: 600,
          }}
          tickFormatter={tickFormatter}
          tickLine={false}
          width={yAxisWidth}
        />

        <XAxis
          allowDataOverflow={false}
          axisLine={false}
          dataKey="date"
          interval={1}
          minTickGap={0}
          tick={({ payload, x, y }: AxisTickProps) => {
            const date = DateTime.fromISO(payload.value);

            return (
              <g transform={`translate(${x},${y})`}>
                <text
                  dy={12}
                  fill={theme.colors.corduroy[700]}
                  fontFamily="Inter"
                  fontSize={axisFontSize}
                  fontWeight={600}
                  textAnchor="middle"
                  x={0}
                  y={0}
                >
                  {date.toLocaleString(DateTime.DATE_SHORT)}
                </text>
              </g>
            );
          }}
          tickLine={false}
        />

        <defs>
          <linearGradient gradientUnits="objectBoundingBox" id={gradId} x1="1" x2="1" y1="0" y2="1">
            <stop stopColor="#03AD9D" />
            <stop offset="1" stopColor="#03AD9D" stopOpacity="0" />
          </linearGradient>
        </defs>

        <Area
          activeDot={{
            fill: theme.colors.white,
            height: 8,
            stroke: theme.colors.primary[900],
            strokeWidth: 2,
            width: 8,
          }}
          dataKey="total"
          fill={`url(#${gradId})`}
          stroke={theme.colors.primary[900]}
          strokeWidth={3}
          type="monotone"
        />

        <Tooltip
          content={({ active, payload }) => {
            const data: Props["data"][number] = payload?.[0]?.payload;
            if (!data || !active) return null;
            return (
              <div className="z-30 rounded-md bg-white px-3 py-2 shadow-sm">
                <div className="text-xs font-medium text-corduroy-900">
                  Date: {`${DateTime.fromISO(data.date).toFormat("LLL d")}`}
                </div>
                <div className="mt-2 flex items-center justify-between gap-5">
                  <div className="text-xs text-corduroy-900">Transaction count:</div>
                  <div className="text-xs font-semibold text-corduroy-900">
                    {typeof data.total === "number" ? getFormattedNumber(data.total) : "-"}
                  </div>
                </div>
              </div>
            );
          }}
          cursor={false}
          wrapperStyle={{ outline: "none" }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

type AxisTickProps = {
  payload: { value: string };
  x: number;
  y: number;
};
