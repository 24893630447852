import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAccount } from "graz";

import logoSrc from "app/assets/images/logo-name.svg";
import { useNetwork } from "app/providers/network";
import { useAppStore } from "app/store";
import { WalletModal } from "features/WalletModal/WalletModal";
import { WalletProviderModal } from "features/WalletProviderModal";
import { getShortenedAddress } from "shared/helpers/getShortenedAddress";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { Button } from "shared/ui/Button";
import { Chip } from "shared/ui/Chip";
import { Icon } from "shared/ui/Icon";
import { Input } from "shared/ui/Input";

import { BurgerMenu } from "./ui/BurgerMenu";
import { ChainPopover } from "./ui/ChainPopover";

const height = /^\d+$/;
const txhash = /^[A-Z\d]{64}$/;
const addr = /^[a-z\d]+1[a-z\d]{38,58}$/;

export const Header = () => {
  const { chainId } = useParams();
  const navigate = useNavigate();
  const { data: account, isConnected } = useAccount();

  const { network } = useNetwork();
  const [search, setSearch] = useState("");
  const [isProviderOpen, setIsProviderOpen] = useState(false);
  const [isWalletOpen, setIsWalletOpen] = useState(false);

  const { openedSidebar, setOpenedSidebar } = useAppStore();

  const lg = useMinWidthMediaQuery("lg");

  const handleSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!search) {
      toast.error("No search defined");

      return;
    }

    if (txhash.test(search)) {
      navigate(`/${chainId}/transactions/${search}`);
      return;
    }

    if (addr.test(search)) {
      navigate(`/${chainId}/address/${search}`);
      return;
    }

    if (height.test(search)) {
      navigate(`/${chainId}/blocks/${search}`);
      return;
    }

    toast.error("Given value is not a tx hash, account or height");
  };

  const isTestnet = network.chainId.toLowerCase().includes("testnet");

  return (
    <div className="fixed inset-x-0 top-0 z-20 flex h-[var(--header-height)] items-center justify-between gap-3 border-b border-corduroy-100 bg-white px-4 xs:gap-4 lg:pr-6">
      <div className="flex h-full items-center border-corduroy-100 lg:w-[var(--sidebar-width)] lg:border-r lg:pl-6 lg:pr-8">
        <div className="flex-1 lg:mr-8">
          <img
            alt="nesa"
            className="w-28 cursor-pointer lg:w-full"
            onClick={() => navigate("/")}
            src={logoSrc}
          />
        </div>
        {lg && isTestnet && <Chip>Testnet</Chip>}
      </div>

      <div className="flex flex-1 items-center justify-center gap-2 lg:justify-stretch">
        <ChainPopover />
        {lg && (
          <>
            <form className="flex-1 px-16" onSubmit={handleSearch}>
              <Input
                className="w-full max-w-2xl"
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search with tx hash / block height / address"
                size="medium"
                startSlot={<Icon name="search" />}
                value={search}
              />
            </form>
            {isConnected ? (
              <Button onClick={() => setIsWalletOpen(true)} variant="filled-light">
                {account && getShortenedAddress(account.bech32Address)}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setIsProviderOpen(true);
                }}
                variant="filled-light"
              >
                Connect wallet
              </Button>
            )}
          </>
        )}
        <div />
      </div>

      {!lg && <BurgerMenu active={openedSidebar} toggleActive={setOpenedSidebar} />}

      <WalletProviderModal isOpen={isProviderOpen} onOpenChange={setIsProviderOpen} />
      <WalletModal isOpen={isWalletOpen} onOpenChange={setIsWalletOpen} />
    </div>
  );
};
