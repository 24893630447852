export const theme = {
  colors: {
    corduroy: {
      50: "#FAFAFC",
      100: "#EEEFF3",
      200: "#E2E4EB",
      300: "#C8CAD0",
      400: "#ADB0B6",
      500: "#8E9197",
      600: "#74777D",
      700: "#5C5F64",
      800: "#393C42",
      900: "#1A1C20",
    },
    gray: {
      300: "#E3EAEA",
    },
    pink: {
      500: "#FF5473",
    },
    primary: {
      40: "#EFFEFF",
      50: "#E2FDFF",
      100: "#D0F9F5",
      200: "#C3EFEB",
      600: "#63E9DC",
      800: "#22C6B6",
      900: "#02BEAC",
      1000: "#03AD9D",
    },
    red: {
      800: "#FD5A5A",
    },
    steel: {
      800: "#3F315E",
      900: "#2A213E",
      1000: "#171123",
    },
    transparent: "transparent",
    white: "#FFFFFF",
    yellow: {
      500: "#FF9E0D",
    },
  },
  extend: {
    dropShadow: {
      mdAll: "0 0 8px rgba(0, 0, 0, 0.1)",
    },
    gridTemplateRows: {
      layout: "var(--header-height) 1fr",
    },
    width: { inherit: "inherit" },
  },
  fontFamily: {
    body: ["Inter"],
    display: ["Inter"],
    fira: ["Fira Code"],
  },
  screens: {
    "2xl": "1536px",
    "3xl": "1920px",
    lg: "1024px",
    md: "768px",
    sm: "640px",
    xl: "1280px",
    xs: "480px",
  },
} as const;
