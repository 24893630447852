import type { PropsWithChildren } from "react";

import { twMerge } from "tailwind-merge";

import type { ClassName } from "shared/types";

export const Chip = ({ children, className }: PropsWithChildren<ClassName>) => {
  return (
    <div
      className={twMerge(
        "flex items-center justify-center rounded-md border border-primary-900 bg-primary-50 px-1.5 py-1 text-xs text-primary-900",
        className,
      )}
    >
      {children}
    </div>
  );
};
