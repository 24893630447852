import { type ReactNode, useState } from "react";

import { Spinner } from "shared/ui/Spinner";
import { Tooltip } from "shared/ui/Tooltip";

type Props = {
  iconSrc: string;
  isLoading?: boolean;
  title: string;
  value: ReactNode;
};

export const StatisticsCard = ({ iconSrc, isLoading, title, value }: Props) => {
  const [withTooltip, setWithTooltip] = useState(false);

  return (
    <div className="flex items-center gap-5 border-b border-b-corduroy-100 p-4">
      <div className="min-w-8">
        <img className="size-8" src={iconSrc} />
      </div>
      <div className="min-w-0 flex-1">
        <h4 className="mb-2 text-sm/none font-thin text-corduroy-500 md:text-base/none">{title}</h4>

        <Tooltip content={withTooltip ? value : undefined} side="top">
          <div
            className="truncate whitespace-nowrap text-lg/none font-semibold md:text-2xl/none"
            ref={(el) => setWithTooltip(Boolean(el && el.scrollWidth > el.offsetWidth))}
          >
            {isLoading ? <Spinner className="size-6" /> : value}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
