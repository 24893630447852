import type { FormEventHandler } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getChainById } from "shared/helpers/getChainById";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Icon } from "shared/ui/Icon";
import { Input } from "shared/ui/Input";
import coverSrc from "shared/ui/assets/cover.png";

import { TransactionListContent } from "./ui/TransactionListContent";

const txhashRegex = /^[A-Z\d]{64}$/;

export const TransactionList = () => {
  const navigate = useNavigate();
  const { chainId } = useParams<{ chainId: string }>();

  const network = getChainById(chainId || "");

  const [hashToSearch, setHashToSearch] = useState("");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (txhashRegex.test(hashToSearch.trim())) {
      navigate(`/${chainId}/transactions/${hashToSearch.trim()}`);
      return;
    }

    toast.error("Tx hash is not valid");
  };

  return (
    <AnimateRoute className="flex flex-col p-0 pb-6">
      <div
        className="flex min-h-44 w-full flex-col items-center justify-center gap-2 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${coverSrc})` }}
      >
        <form
          className="mx-2 w-full max-w-full select-none px-4 text-center text-2xl/none text-primary-900 md:max-w-[50%] md:text-4xl"
          onSubmit={handleSubmit}
        >
          <Input
            className="w-full"
            onChange={(e) => setHashToSearch(e.target.value)}
            placeholder="Search Transaction by Tx Hash"
            startSlot={<Icon className="text-corduroy-800" name="search" />}
            value={hashToSearch}
          />
        </form>
      </div>

      <div className="">
        <TransactionListContent network={network} />
      </div>
    </AnimateRoute>
  );
};
