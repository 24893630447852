import { AnimatePresence, motion } from "framer-motion";
import { twJoin } from "tailwind-merge";

type Props = {
  active: boolean;
  toggleActive: (opened: boolean) => void;
};

export const BurgerMenu = ({ active, toggleActive }: Props) => {
  const className = "absolute rounded-sm h-0.5 w-6 bg-corduroy-500";
  return (
    <AnimatePresence>
      <button className="relative size-6" onClick={() => toggleActive(!active)}>
        <motion.div
          animate={active ? { rotate: 135, translateY: 7 } : { rotate: 0, translateY: 0 }}
          className={twJoin(className, "top-1")}
          initial={{ rotate: 0, translateY: 0 }}
        />
        <motion.div
          animate={active ? { opacity: 0 } : { opacity: 1 }}
          className={twJoin(className, "top-1/2 -translate-y-1/2")}
          initial={{ opacity: 1 }}
        />
        <motion.div
          animate={active ? { rotate: -135, translateY: -7 } : { rotate: 0, translateY: 0 }}
          className={twJoin(className, "bottom-1")}
          initial={{ rotate: 0, translateY: 0 }}
        />
      </button>
    </AnimatePresence>
  );
};
