import { create } from "zustand";

type AppStore = {
  openedSidebar: boolean;
  setOpenedSidebar: (opened: boolean) => void;
};

export const useAppStore = create<AppStore>((set) => ({
  openedSidebar: false,
  setOpenedSidebar: (opened: boolean) => {
    set({ openedSidebar: opened });
  },
}));
